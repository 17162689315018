.ticker {
  overflow: hidden;
  display: flex;
  width: 100vw;
}
.ticker__list {
  display: flex;
  margin-top: 72px;
  animation: ticker 35s infinite linear;
}

.ticker__item {
  margin-right: 64px;
  width: fit-content;
  display: flex;
}

@keyframes ticker {
  100% {
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 785px) {
  .ticker__list {
    margin-top: 42px;
  }

  .ticker__item {
    margin-right: 34px;
    img {
      height: 55px;
    }
  }
}
