.statistic {
  padding: 100px 50px;
  background: #f3f5f7;
}

.statistic-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1410px;
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 28px;

  & > div {
    width: 33%;
    text-align: center;

    span {
      font-weight: 700;
      font-size: 80px;
      line-height: 108px;
      color: #111111;
    }

    div {
      font-weight: 400;
      font-size: 24px;
      line-height: 180%;
      color: #111111;
    }
  }
}

@media screen and (max-width: 1024px) {
  .statistic-container {
    & > div {
      width: 33%;
      text-align: center;

      span {
        font-size: 8vw;
        line-height: 130%;
      }

      div {
        font-size: 2.3vw;
        line-height: 180%;
      }
    }
  }
}

@media screen and (max-width: 785px) {
  .statistic {
    padding: 50px 24px;
  }

  .statistic-container {
    flex-direction: column;
    align-items: center;
    gap: 40px;

    & > div {
      width: unset;

      span {
        font-size: 64px;
        line-height: 86px;
      }

      div {
        font-size: 20px;
        line-height: 120%;
      }
    }
  }
}
