.popup-content {
  width: 100%;
}

.navbar {
  width: calc(100% - 100px);
  height: 60px;
  padding: 50px 50px 0;
  background: transparent;
  position: absolute;

  .logo {
    z-index: 5;
  }
}

.burger-menu,
.burger-menu.open {
  user-select: none;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  right: 50px;
  top: 50px;
  z-index: 9999;
  padding: 17px;
  border-radius: 50%;
}

.burger-menu {
  background: #0bb3f0;
  .bar1,
  .bar2,
  .bar3 {
    background-color: #ffffff;
  }
}
.burger-light {
  background: #ffffff;
  .bar1,
  .bar2,
  .bar3 {
    background-color: #111111;
  }
}
.burger-menu.open {
  background: #ffffff;
}

.bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 3px;
  margin: 4px 0;
  transition: 0.4s;
}

.burger-menu.open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 4px);
  transform: rotate(-45deg) translate(-4px, 4px);
  background-color: #111111;
}

.burger-menu.open .bar2 {
  opacity: 0;
}

.burger-menu.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
  background-color: #111111;
}

.menu {
  width: 100%;
  display: block;
  text-align: center;
  user-select: none;
  padding: 0px;
}

.columns-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 80px;
}

.menu ul {
  position: relative;
  margin-top: 70px;
  font-size: 24px;
  padding: 0px;
  width: 40%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  a {
    display: block;
    width: fit-content;
  }
}
.menu li {
  list-style: outside none none;
  margin: 10px 0px;
  padding: 0;
  cursor: pointer;
  font-weight: 700;
  font-size: 55px;
  line-height: 65px;
  text-align: center;
  color: #ffffff;
}

.disabled {
  opacity: 0.4;

  span {
    font-size: 56px;
    line-height: 75.6px;
  }
}

.languages {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;

  .lang {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ffffff;
    width: 56px;
    height: 56px;
    border: 2px solid #ffffff;
    background: transparent;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .active {
    background: #ffffff;
    color: #111111;
  }
}

@media screen and (max-width: 1024px) {
  .popup-content {
    width: 100% !important;
  }
}

@media screen and (max-width: 785px) {
  .popup-content {
    width: unset !important;
    display: flex;
    align-items: center;
  }

  .navbar {
    width: calc(100% - 40px);
    padding: 65px 20px 0;
  }

  .burger-menu,
  .burger-menu.open {
    right: 20px;
    top: 64px;
    padding: 12.5px;
  }

  .burger-menu .bar1,
  .bar2,
  .bar3 {
    width: 25px;
    height: 3px;
    background-color: #ffffff;
    margin: 4px 0;
    transition: 0.4s;
  }

  .burger-light {
    .bar1,
    .bar2,
    .bar3 {
      background-color: #111111;
    }
  }

  .languages {
    flex-wrap: wrap;

    .lang {
      font-size: 16px;
      line-height: 18px;
      width: 30px;
      height: 30px;
    }
  }

  .columns-container {
    flex-direction: column;
    justify-content: center;
    gap: 0;
  }

  .menu ul {
    width: 100%;
  }

  .menu ul:first-child {
    margin-bottom: 0;
  }

  .menu ul:last-child {
    margin-top: 0;
  }

  .menu li {
    margin: 35px 0px;
    padding: 0;
    cursor: pointer;
    font-weight: 700;
    font-size: 40px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
    width: fit-content;
    display: block;
  }

  .disabled {
    opacity: 0.4;

    span {
      font-size: 36px;
      line-height: 0.6px;
    }
  }
}
