.pricing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pricing-container {
  max-width: 1172px;
  padding: 200px 170px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #111;
  gap: 80px;

  h1 {
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin: 0 0 30px;
  }

  .pricing-text {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    text-align: center;
  }
}

.pricing-targets {
  max-width: 1092px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background: #f3f5f7;
  gap: 50px;
  padding: 40px;

  h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }

  &-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-item {
    width: 20.95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 0 2.84% 0;
    border-right: 1px solid #111;
    text-align: center;
  }

  &-item:nth-child(1) {
    padding-left: 0;
  }
  &-item:nth-child(4) {
    padding-right: 0;
    border-right: unset;
  }
}

.pricing-items {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.pricing-btn {
  width: 184px;
  position: absolute;
  top: 40px;
  right: 40px;
}

.pricing-item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 30px;
  border: 2px solid;

  h3 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
}
.pricing-item:nth-child(1),
.pricing-item:nth-child(5) {
  border-color: #0bb3f0;

  .pricing-item-content {
    border-color: inherit;
  }
}
.pricing-item:nth-child(2),
.pricing-item:nth-child(6) {
  border-color: #faca15;
  .pricing-item-content {
    border-color: inherit;
  }
}
.pricing-item:nth-child(3) {
  border-color: #e35019;
  .pricing-item-content {
    border-color: inherit;
  }
}
.pricing-item:nth-child(4) {
  border-color: #111111;
  .pricing-item-content {
    border-color: inherit;
  }
}

.pricing-item-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 0;
  border-bottom: 1px solid;
}
.pricing-item-content:last-child {
  border-bottom: unset;
  padding-bottom: 0;
}

.pricing-item-content-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pricing-item-content-text {
  display: block;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.reasons {
  background: #111;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reasons-container {
  max-width: 1412px;
  padding: 100px 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  gap: 50px;

  h1 {
    width: 50%;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    text-align: center;
  }

  .reasons-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }

  .reasons-item {
    width: 23%;
    padding: 2.83% 3.96%;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.05);
    display: flex;
    flex-direction: column;
    gap: 24px;

    h4 {
      margin: 0;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      text-align: center;
      white-space: pre-line;
    }

    div {
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .pricing-container {
    padding: 175px 50px 50px;

    h1 {
      font-size: 48px;
    }
  }

  .reasons-container {
    padding-top: 50px;

    h1 {
      font-size: 48px;
    }

    .reasons-item {
      h4 {
        font-size: 22px;
      }

      div {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 785px) {
  .pricing-container {
    padding: 175px 25px 50px;

    h1 {
      font-size: 32px;
    }

    .pricing-text {
      font-size: 18px;
    }
  }

  .pricing-targets {
    gap: 25px;
    padding: 20px;

    h2 {
      font-size: 24px;
    }

    &-items {
      flex-direction: column;
      align-items: center;
      gap: 25px;
    }

    &-item {
      width: 80%;
      max-width: 300px;
      padding: 0;
      border: unset;
    }
  }

  .pricing-btn {
    top: 20px;
    right: 20px;
    width: 120px;
    font-size: 14px;
    height: 45px;
  }

  .pricing-item {
    padding: 20px;

    h3 {
      font-size: 26px;
      line-height: 45px;
    }
  }

  .pricing-item-content {
    gap: 16px;
    padding: 16px 0;
  }

  .pricing-item-content-title {
    font-size: 18px;
  }

  .pricing-item-content-text {
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .reasons-container {
    padding-bottom: 50px;

    h1 {
      width: 100%;
      font-size: 32px;
    }

    .reasons-items {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 25px;
    }

    .reasons-item {
      padding: 20px 28px;
      width: 82%;
      max-width: 300px;
    }
  }
}
