.metric {
  width: 100%;
  background-color: #111111;
  display: flex;
  justify-content: center;
  color: #fff;
}

.metric-container {
  padding: 100px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.metric-title {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 108px;
  margin: 0;
}

.metrics {
  margin-top: 50px;
  max-width: 1412px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  img {
    height: 108px;
  }

  h2 {
    margin: 0;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.metrics-item {
  width: 22%;
  display: flex;
  flex-direction: column;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.metrics-item span:nth-of-type(2) {
  margin-top: 10px;
  line-height: 180%;
  font-size: 12px;
}

@media screen and (max-width: 1055px) {
  .metrics {
    // max-width: 580px;
  }
}

@media screen and (max-width: 785px) {
  .metric-container {
    padding: 50px 50px;
  }

  .metric-title {
    font-size: 32px;
    line-height: 43px;
  }

  .metrics {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    margin-top: 25px;

    h2 {
      font-size: 38px;
    }
  }

  .metrics-item {
    width: 80%;
    font-size: 20px;
    line-height: 26px;

    img {
      height: 65px;
    }
  }
}
