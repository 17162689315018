.projects {
  max-width: 100%;
  background: #111111;
  overflow: hidden;
}
.border_radius {
  border-radius: 30px;
}

.projects-container {
  padding: 100px 50px;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 108px;
    text-align: center;
    color: #ffffff;
    margin: 0;
  }
}

.no-projects {
  color: white;
  text-align: center;
  margin-top: 30px;
  font-size: 3vw;
}

.projects-swiper {
  margin-top: 40px;
}

.projects-card {
  width: 100%;
  background: #faca15;
  border: 0;
  border-radius: 32px;
  position: relative;
  cursor: pointer;
  user-select: none;

  .projects-img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 30px;
    z-index: 5;
  }

  .projects-gradient {
    color: white;
    display: none;
    position: absolute;
    bottom: 16px;
    left: -16px;
    width: 100%;
    height: 60%;
    text-align: center;

    h3 {
      margin: 0;
      font-weight: 700;
      font-size: 2.7vw;
      line-height: 180%;
    }

    h4 {
      margin: 0;
      font-weight: 700;
      font-size: 1.7vw;
      line-height: 180%;
    }

    img {
      width: 19.5%;
      margin-top: 7%;
    }
  }

  &:hover {
    &::after {
      content: "";
      position: absolute;
      top: -16px;
      left: -16px;
      width: 100%;
      height: 100%;
      border-radius: 30px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 50%);
      opacity: 0.7;
      z-index: 5;
    }

    .projects-img {
      transform: translate(-16px, -16px);
      border: unset;
    }

    .projects-gradient {
      display: block;
      z-index: 10;
    }
  }
}

.swiper-slide:last-of-type {
  height: 100%;

  a {
    height: 100%;
  }
}

.projects-all-card {
  width: 100%;
  height: calc(100% - 10px);
  background: #111111;
  border: 5px solid #faca15;
  border-radius: 32px;
  cursor: pointer;
  user-select: none;
  position: relative;

  div {
    width: 100%;
    height: 62%;
    position: absolute;
    bottom: 0;
    text-align: center;
  }

  h4 {
    margin: 0;
    font-weight: 700;
    font-size: 2.7vw;
    line-height: 140%;
    color: #ffffff;
  }

  img {
    width: 25%;
    margin-top: 4%;
  }

  &:hover {
    background: #faca15;

    h4 {
      color: #111111;
    }
  }
}

.swiper {
  overflow: visible !important;

  .swiper-slide {
    height: auto;
  }
}

.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal {
  top: unset !important;
  right: 0;
  bottom: -50px;
  width: 100px !important;
  height: 6px !important;
  margin-left: auto;
  margin-right: auto;
  background: rgba(250, 202, 21, 0.2);
  border-radius: 30px;
  span {
    border-radius: 30px !important;
    opacity: 1;
    background: #faca15;
  }
}

@media screen and (max-width: 1024px) {
  .projects-card {
    .projects-gradient {
      display: none;

      & > * {
        display: none;
      }
    }

    &:hover {
      &::after {
        display: none;
        content: "";
        top: 0;
        left: 0;
      }

      .projects-img {
        transform: unset;
      }
    }
  }

  .projects-all-card {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      bottom: unset;
      height: auto;
      position: relative;
    }

    img {
      display: none;
    }

    &:hover {
      img {
        display: inline-block;
      }
    }
  }
}

@media screen and (max-width: 785px) {
  .projects-container {
    width: calc(100% - 100px);
    padding: 50px;
    h1 {
      font-size: 7vw;
      line-height: 7.5vw;
      margin-bottom: 30px;
    }
  }

  .projects-grid {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 50px;
  }

  .projects-card {
    background: #111111;
    max-width: 370px;
  }

  .projects-all-card {
    display: none;
  }
}

.swiper-slide > a {
  display: flex;
  justify-content: center;
}
