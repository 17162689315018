.blogs {
  max-width: 100%;
  padding: 40px 50px 100px;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blogs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1420px;
  width: 100%;

  a {
    display: flex;
    width: 31%;
    max-width: 450px;

    .blog-item-bg {
      margin-bottom: 16px;
    }
  }
}

.blogs-button {
  margin-top: 40px !important;
}

.blogs-card {
  background: #fff;
  border-radius: 30px;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 32px;
  position: relative;

  .blog-tags {
    position: absolute;
    top: 26px;
    left: 30px;
  }

  img {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    width: 100%;
    margin-bottom: 30px;
    // aspect-ratio: 4 / 3;
    object-position: top;
    object-fit: cover;
  }

  h3 {
    font-weight: 700;
    margin: 0;
    font-size: 24px;
    line-height: 35px;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 32px;
  }

  span {
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding: 0 32px;
  }
}

@media screen and (max-width: 785px) {
  .blogs {
    padding: 50px 24px;
  }

  .blogs-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;

    a {
      width: 80%;
    }
  }

  .blogs-card {
    max-width: unset;
    width: 100%;
    margin: 0;

    h3 {
      font-size: 20px;
      line-height: 22px;
    }

    span {
      font-size: 14px;
      margin-top: 4px;
    }

    .blog-tags {
      position: absolute;
      justify-content: unset;
      top: 16px;
      left: 20px;
    }
  }
}
