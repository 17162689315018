.footer {
  max-width: 100%;
  padding: 100px 50px 60px;
  display: flex;
  justify-content: center;
}

.footer-container {
  max-width: 1412px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-section {
  width: 23.5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;

  h4 {
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
    margin: 0;
  }
}

.footer-title {
  position: relative;
  padding-top: 20px;
}

.footer-sarani {
  width: 131px;
}

.footer-logo {
  position: absolute;
  top: 0;
  right: -8px;
  width: 20px;
  height: 20px;
}

.footer-copyright {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #111111;
}

.footer-social {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

@media screen and (max-width: 1024px) {
  .footer-section {
    width: max-content;
  }
}

@media screen and (max-width: 785px) {
  .footer {
    padding: 50px 24px;
  }

  .footer-container {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }

  .footer-section {
    width: 100%;
    align-items: center;
  }
}
