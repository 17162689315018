.home {
  width: 100%;
  display: flex;
}

.logo {
  position: absolute;
  width: 180px;
  top: 25px;
}

.home-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 150px;
  .home-logo {
    margin: 160px auto 0;
    display: block;
  }
}

.title-container {
  display: flex;
  justify-content: center;
}

.title {
  display: flex;
  align-items: flex-end;
  position: relative;
  height: 23vh;
  animation: fadeInTitle ease 1s;
  animation-delay: 1.1s;
  animation-fill-mode: both;
}

.sarani {
  height: 13vh;
}

.title-logo {
  position: absolute;
  top: 0;
  right: -3vh;
  width: 10vh;
  height: 10vh;
}

.subtitle {
  display: flex;
  justify-content: center;
  animation: fadeInTitle ease 1s;
  animation-delay: 1.2s;
  animation-fill-mode: both;

  span {
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 8vh;
    line-height: normal;
    margin-top: 1.6vh;
    padding-right: 1.5vh;
    padding-left: 0.2vh;
  }

  span::before {
    content: "";
    position: absolute;
    width: 1.5vh;
    height: 1.5vh;
    bottom: 2.7vh;
    right: 0;
    border-radius: 50%;
  }

  span:nth-child(1)::before {
    background-color: #faca15;
  }
  span:nth-child(2)::before {
    background-color: #0bb3f0;
  }
  span:nth-child(3)::before {
    background-color: #e35019;
  }
}

.solutions {
  font-weight: 700;
  font-size: 2.5vh;
  line-height: 3.3vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;

  span {
    padding-right: 2.8vh;
    padding-left: 2.4vh;
    position: relative;
  }

  span::before {
    content: "";
    position: absolute;
    width: 0.8vh;
    height: 0.8vh;
    right: 0;
    border-radius: 50%;
    background: #faca15;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  span:last-child::before {
    display: none;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  margin-top: 48px;
}

.ylw-btn,
.ylw-border-btn,
.wh-btn {
  width: 281px;
  padding: 18px 20px;
  text-align: center;
  border-radius: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
}

.ylw-btn {
  background: var(--sarani-yellow, #faca15);
  border: 3px solid var(--sarani-yellow, #faca15);
}

.ylw-border-btn {
  border: 3px solid var(--sarani-yellow, #faca15);
}

.wh-btn {
  border: 3px solid var(--sarani-yellow, #fff);
  background: #fff;
  color: #111111;
}

.arrow {
  display: none;
}

@media screen and (max-width: 1100px) {
  .home-container {
    .home-logo {
      margin-top: 25vh;
      width: 80%;
    }
  }
  .solutions {
    font-size: 2vh;
    line-height: 3vh;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .ylw-btn,
  .ylw-border-btn,
  .wh-btn {
    width: 200px;
    padding: 12px 16px;
    font-size: 16px;
  }

  .title {
    height: 17vh;
  }

  .sarani {
    height: 10vh;
  }

  .title-logo {
    position: absolute;
    top: 0;
    right: -2vh;
    width: 7vh;
    height: 7vh;
  }

  .subtitle {
    span {
      font-size: 6vh;
      padding-right: 1.2vh;
    }

    span::before {
      width: 1vh;
      height: 1vh;
    }
  }

  .attention {
    font-size: 5vh;
    margin-top: 24vh;
  }
}

@media screen and (max-width: 785px) {
  .home-container {
    .home-logo {
      width: 75%;
      margin-top: 30vh;
    }
  }
  .logo {
    top: 64px;
    left: 20px;
    width: 120px;
  }

  .title {
    height: 9vh;
  }

  .sarani {
    height: 5vh;
  }

  .title-logo {
    top: 0;
    right: -1vh;
    width: 4vh;
    height: 4vh;
  }

  .subtitle {
    span {
      font-size: 3.5vh;
      padding-right: 1vh;
    }

    span::before {
      width: 0.7vh;
      height: 0.7vh;
      bottom: 0.7vh;
    }
  }

  .buttons {
    gap: 15px;
  }

  .solutions {
    max-width: 470px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    span:nth-child(2)::before {
      display: none;
    }
  }

  .arrow {
    display: block;
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 600px) {
  .home {
    height: 85vh;
  }

  .solutions {
    max-width: 400px;
    font-size: 1.8vh;
  }

  .subtitle {
    span {
      font-size: 3.2vh;
      padding-right: 0.8vh;

      &::before {
        bottom: 1vh;
      }
    }
  }

  .ylw-btn,
  .ylw-border-btn,
  .wh-btn {
    width: 143px;
    padding: 10px 12px;
    font-size: 15px;
  }
}

@keyframes fadeInTitle {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}
