.team-members {
  width: 100%;
  background-color: #111111;
  display: flex;
  justify-content: center;
  color: #fff;
}
.team-members-container {
  padding: 100px 50px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-members-title {
  width: 50%;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 108px;
  margin: 0;

  span {
    color: #faca15;
  }
}

.team-happiness {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
}

.team-members-categories {
  margin-top: 50px;
  margin-bottom: 100px;
  max-width: 1412px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h2 {
    margin: 0;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.team-members-item {
  width: 22%;
  display: flex;
  flex-direction: column;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.cities {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  gap: 96px;

  span {
    position: relative;
  }

  span::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    right: -56px;
    border-radius: 50%;
    background: #faca15;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  span:last-child::before {
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  .team-members-title {
    width: 90%;
  }

  .team-members-categories {
    h2 {
      font-size: 60px;
      line-height: 76px;
    }
  }

  .cities {
    width: 450px;
    flex-wrap: wrap;
    font-size: 25px;
    gap: 30px;

    span::before {
      width: 9px;
      height: 9px;
      right: -19px;
    }

    span:nth-child(3)::before {
      display: none;
    }
  }
}

@media screen and (max-width: 785px) {
  .team-members-container {
    padding: 50px 24px;
  }

  .team-happiness {
    gap: 30px;
    img {
      width: 30%;
    }
  }

  .team-members-title {
    font-size: 32px;
    line-height: 43px;
  }

  .team-members-categories {
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 35px;

    h2 {
      font-size: 44px;
      line-height: 66px;
    }
  }

  .team-members-item {
    width: 22%;
    font-size: 18px;
  }

  .cities {
    width: 350px;
    font-size: 19px;
    gap: 30px;

    span::before {
      width: 9px;
      height: 9px;
      right: -19px;
    }
  }
}
