.moving-arrow {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 60px;
  background-color: #fff;
  transition: transform 0.3s ease-in-out;
  user-select: none;
}

.moving-arrow.animate {
  animation: arrow-bounce 1s infinite ease-in-out;
}

.moving-arrow.hidden {
  opacity: 0;
}

.moving-arrow .moving-arrow-icon {
  font-size: 40px;
}

@keyframes arrow-bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
