.project-details {
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.project-details-container {
  display: flex;
  flex-direction: column;
}

.project-banner {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 1512 / 866;
  max-height: 866px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .project-banner-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    color: #ffffff;
    font-weight: 700;
  }

  h1 {
    margin: 0;
    font-size: 133px;
    line-height: 186px;
  }

  h3 {
    margin: 0;
    font-size: 80px;
    line-height: 112px;
    color: #ffffff;
  }
}

.project-banner-overlay {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 55%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 50%);
  opacity: 0.7;
}

.project-title {
  padding: 100px 170px 0;

  div {
    width: inherit;
    font-weight: 700;
    font-size: 80px;
    line-height: 108px;
    color: #111111;
  }
}

.divided-text {
  column-count: 2;
  column-gap: 30px;
  margin: 0 auto;
  padding: 50px 170px 100px;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.02em;
    text-align: justify;
    color: #111111;
    white-space: normal;
    margin: 0;
  }
}

.project-videos {
  padding: 0 170px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.project-video-vertical {
  width: 30%;
  margin-bottom: 100px;
}

.project-video-horizontal {
  margin-bottom: 100px;
}

.project-gallery {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  img:first-child {
    padding-top: 0;
  }

  .square-img {
    width: 50%;
    margin-top: -1px;
  }
  .rect-img {
    width: 100%;
    margin-top: -1px;
  }

  .padding-img {
    width: calc(100% - 340px);
    padding: 100px 0;
    max-width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .project-banner {
    h1 {
      font-size: 10vh;
      line-height: 11.5vh;
    }

    h3 {
      font-size: 7vh;
      line-height: 8vh;
    }
  }

  .project-title {
    div {
      font-size: 7vh;
      line-height: 8vh;
    }
  }
}

@media screen and (max-width: 785px) {
  .project-banner {
    aspect-ratio: unset;

    h1 {
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .project-title {
    padding: 50px 24px 0;

    div {
      font-size: 40px;
      line-height: 48px;
    }
  }

  .divided-text {
    column-count: unset;
    margin: 0 auto;
    white-space: pre-wrap;
    padding: 20px 24px 72px;
  }

  .project-videos {
    padding: 0 24px;
    flex-direction: column;
  }

  .project-video-vertical {
    width: unset;
    max-width: 380px;
    margin-bottom: 40px;
  }

  .project-video-horizontal {
    padding: 0;
    margin-bottom: 40px;
  }

  .project-gallery {
    flex-direction: column;
    flex-wrap: unset;
    justify-content: unset;
    margin-bottom: 50px;

    .square-img {
      width: 100%;
    }

    .padding-img {
      width: calc(100% - 48px);
      margin-left: auto;
      margin-right: auto;
      padding: 0 0 30px;
    }
  }
}
