.agency {
  padding: 100px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #f3f5f7;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 108px;
    margin: 0;
  }

  h4 {
    margin: 15px 0 100px;
    font-weight: 500;
    font-size: 24px;
    line-height: 180%;
    letter-spacing: -0.02em;
  }

  div {
    width: 252px;
    height: 370px;
    background: #faca15;
    border-radius: 30px;
    padding: 40px;
    position: relative;

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 140%;
    }

    img {
      position: absolute;
      bottom: 40px;
      right: 40px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 785px) {
  .agency {
    padding: 50px 24px;

    h1 {
      font-size: 40px;
      line-height: 54px;
    }

    h4 {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      margin: 20px 0 30px;
    }
  }
}
