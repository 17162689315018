.testi {
  width: calc(100% - 100px);
  background: #0bb3f0;
  padding: 80px 50px 90px;
  display: flex;
  justify-content: center;
}

.testi-container {
  max-width: 100%;

  h1 {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 108px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 40px;
  }
}

.testi-cards {
  max-width: 1300px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  user-select: none;

  .swiper {
    overflow: hidden !important;
    padding: 0px 60px;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
  }

  .swiper-button-prev {
    left: 0;
    height: 100%;
    top: 0;
    background: #0bb3f0;
    width: 32px;
    margin-top: 0;
    color: white;
  }
  .swiper-button-next {
    right: 0;
    height: 100%;
    top: 0;
    background: #0bb3f0;
    width: 40px;
    margin-top: 0;
    color: white;
  }
}

.testi-card {
  max-width: 310px;
  padding: 30px;
  background: #ffffff;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
}

.testi-logo {
  height: 100px;
  display: flex;
  align-items: center;
}

.testi-card-title {
  margin: 8px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #111111;
}

.testi-card-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #111111;
}

.testi-person {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 34px;
  align-items: flex-start;
  min-height: 66px;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

.testi-info {
  font-size: 16px;
  line-height: 22px;
  color: #111111;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.testi-name {
  font-weight: 700;
}

.testi-job {
  font-weight: 500;
  letter-spacing: -0.02em;
}

.testi-companies {
  margin-top: 110px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 90px;
}

@media screen and (max-width: 1200px) {
  .testi-cards {
    max-width: 800px;
  }
  .testi-person {
    align-items: center;
  }
}

@media screen and (max-width: 785px) {
  .testi {
    width: fit-content;
    padding: 50px 24px;
  }

  .testi-container {
    width: 100%;
    h1 {
      font-size: 32px;
      line-height: 43px;
      letter-spacing: 1px;
    }
  }

  .testi-logo {
    justify-content: center;
  }

  .testi-card-title {
    text-align: center;
  }

  .testi-person {
    flex-direction: column;
    align-items: center;
  }

  .testi-info {
    span {
      text-align: center;
    }
  }

  .testi-cards {
    max-width: 370px;
    flex-direction: column;
    gap: 15px;
  }

  .testi-card {
    width: unset;
    max-width: 310px;
  }

  .testi-companies {
    margin-top: 50px;
    gap: 60px;
    img {
      max-width: 140px;
    }
  }
}