.added-value {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #fff;
  position: relative;
  background-image: url(../../assets/images/added-value.png);
}

.added-value::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: 100% auto;
  opacity: 0.7;
}

.added-value::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(17, 17, 17, 0.7), rgba(17, 17, 17, 0.7));
  backdrop-filter: blur(12px);
}

.added-value-container {
  padding: 100px 50px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
  z-index: 10;

  h3 {
    width: 70%;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    text-align: center;
    white-space: break-spaces;
    margin: 0;
  }
}

.added-value-title {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 108px;
  margin: 0;

  span {
    color: #0bb3f0;
  }
}

.values-categories {
  max-width: 1412px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.values-item {
  width: 22%;
  display: flex;
  flex-direction: column;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;

  img {
    height: 108px;
  }
}

@media screen and (max-width: 1100px) {
  .added-value-container {
    gap: 40px;

    h3 {
      width: 100%;
      font-size: 36px;
    }
  }

  .values-item {
    font-size: 20px;
    img {
      height: 90px;
    }
  }
}

@media screen and (max-width: 785px) {
  .added-value-container {
    padding: 50px 24px;
  }

  .added-value-title {
    font-size: 32px;
    line-height: 43px;
  }

  .added-value-container {
    h3 {
      font-size: 23px;
    }
  }

  .values-categories {
    flex-direction: column;
    align-items: center;
  }

  .values-item {
    width: 100%;
    font-size: 17px;
  }
}
