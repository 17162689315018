.ourproject {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
}

.ourproject-container {
  padding: 100px 50px;
  max-width: 1412px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ourproject-title {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 108px;
  margin: 0;
}

@media screen and (max-width: 785px) {
  .ourproject-container {
    padding: 154px 24px 50px;
  }

  .ourproject-title {
    font-size: 40px;
    line-height: 120%;
  }
}
