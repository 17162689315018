.team {
  display: flex;
  justify-content: center;
  position: relative;
  color: #111111;
}

.team-container {
  max-width: 1172px;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-weight: 700;
    font-size: 80px;
    line-height: 108px;
    margin: 0;
  }

  h4 {
    width: 820px;
    margin: 50px 0 0;
    font-weight: 700;
    font-size: 48px;
    line-height: 65px;
    text-align: center;
    letter-spacing: -0.02em;
  }

  h6 {
    text-align: center;
    margin: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }
}

.team-note {
  border-radius: 80px;
  border: 3px solid #111111;
  padding: 32px 40px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.48px;
  margin: 50px 24px 0;
  text-align: center;
}

.team-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -45px;

  .team-member {
    width: min-content;
    padding-left: 45px;
    padding-top: 50px;
  }

  img {
    transform: scaleX(-1);
    width: 150px;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  div {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.02em;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.02em;
    opacity: 0.5;
  }
}

@media screen and (max-width: 1024px) {
  .team-cards {
    padding: 10px;
  }

  .team-note {
    padding: 20px 32px;
  }

  .team-container {
    h4 {
      width: 90%;
      margin: 50px 24px 0;
    }
  }
}

@media screen and (max-width: 785px) {
  .team-container {
    padding: 50px 24px;
    width: 100%;

    h1 {
      font-size: 40px;
      line-height: 120%;
    }

    h4 {
      width: fit-content;
      margin: 50px 0 20px;
      font-size: 32px;
      line-height: 43px;
    }

    h6 {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .team-cards {
    width: 100%;
    margin-left: -20px;

    .team-member {
      max-width: 140px;
      padding-left: 20px;
      padding-top: 30px;
    }

    img {
      transform: scaleX(-1);
      width: 140px;
      border-radius: 50%;
      margin-bottom: 10px;
    }
  }

  .team-note {
    font-size: 18px;
    padding: 7px 25px;
  }
}
