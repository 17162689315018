.faq {
  position: relative;
}

.faq-gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='1512' height='1485' viewBox='0 0 1512 1485' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_929_4064)'%3E%3Crect width='1512' height='1485' fill='%23111111'/%3E%3Cg opacity='0.5' filter='url(%23filter0_f_929_4064)'%3E%3Ccircle cx='1214.5' cy='272.5' r='272.5' fill='%23E35019'/%3E%3C/g%3E%3Cg opacity='0.5' filter='url(%23filter1_f_929_4064)'%3E%3Ccircle cx='791.5' cy='884.5' r='237.5' fill='%230BB3F0'/%3E%3C/g%3E%3Cg filter='url(%23filter2_f_929_4064)'%3E%3Ccircle cx='149' cy='1212' r='115' fill='%23FACA15'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_929_4064' x='542' y='-400' width='1345' height='1345' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='200' result='effect1_foregroundBlur_929_4064'/%3E%3C/filter%3E%3Cfilter id='filter1_f_929_4064' x='154' y='247' width='1275' height='1275' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='200' result='effect1_foregroundBlur_929_4064'/%3E%3C/filter%3E%3Cfilter id='filter2_f_929_4064' x='-366' y='697' width='1030' height='1030' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='200' result='effect1_foregroundBlur_929_4064'/%3E%3C/filter%3E%3CclipPath id='clip0_929_4064'%3E%3Crect width='1512' height='1485' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-repeat: repeat-y;
  background-size: 100% auto;
}

.faq-container {
  padding: 111px 100px 150px 188px;
  height: auto;
  z-index: 20;

  .faq-content {
    z-index: 9999;
  }

  h1 {
    font-weight: 700;
    font-size: 80px;
    line-height: 108px;
    position: relative;
    color: white;
  }

  & > div {
    display: flex;
    gap: 175px;
  }

  img {
    height: 261px;
    z-index: 1;
  }
}

.container {
  margin: 0;
  width: 100%;
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: 50px;

  .accordion-item {
    position: relative;
  }

  button {
    width: 100%;
    padding-right: 50px;
    position: relative;
    text-align: left;
    font-family: "Galano Grotesque";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #ffffff;
    border: none;
    background: none;
    outline: none;

    &:hover,
    &:focus {
      cursor: pointer;
      color: #faca15;
      &::after {
        cursor: pointer;
        color: #faca15;
        border: 1px solid #faca15;
      }
    }
  }

  button[aria-expanded="true"] {
    .icon {
      &::after {
        width: 0;
      }
    }
    + .accordion-content {
      opacity: 1;
      max-height: min-content;
      transition: all 200ms linear;
      will-change: opacity, max-height;
    }
  }

  button[aria-expanded="false"] {
    .icon {
      width: 0;
    }
  }

  .icon {
    display: inline-block;
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.4892 15.4815L12.0354 7.03541C12.0159 7.0159 11.9842 7.0159 11.9647 7.03543L3.51479 15.4854' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto 0;
    width: 24px;
    height: 24px;

    &::after {
      display: block;
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto 0;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.51081 8.51848L11.9646 16.9646C11.9841 16.9841 12.0158 16.9841 12.0353 16.9646L20.4852 8.51464' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }

  .accordion-content {
    opacity: 0;
    max-height: 0;
    border-bottom: 1px solid white;
    overflow: hidden;
    transition: opacity 500ms linear, max-height 500ms linear;
    will-change: opacity, max-height;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #ffffff;
    border-bottom: 2px solid rgba(243, 245, 247, 0.1);
    margin-top: 50px;
    padding-bottom: 50px;
    padding-right: 120px;
  }
}

@media screen and (max-width: 1440px) {
  .faq-container {
    padding: 111px 100px 130px 115px;

    & > div {
      gap: 120px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .faq-container {
    padding: 100px 50px;

    & > div {
      gap: 60px;
    }
  }

  .accordion {
    .accordion-content {
      padding-right: 30px;
    }
  }
}

@media screen and (max-width: 785px) {
  .faq-gradient {
    background-image: url("data:image/svg+xml,%3Csvg width='390' height='911' viewBox='0 0 390 911' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_975_3643)'%3E%3Crect width='390' height='911' fill='%23111111'/%3E%3Cg filter='url(%23filter0_f_975_3643)'%3E%3Ccircle cy='1212' r='115' fill='%23FACA15'/%3E%3C/g%3E%3Cg opacity='0.5' filter='url(%23filter1_f_975_3643)'%3E%3Ccircle cx='389.5' cy='272.5' r='272.5' fill='%23E35019'/%3E%3C/g%3E%3Cg opacity='0.5' filter='url(%23filter2_f_975_3643)'%3E%3Ccircle cx='195.5' cy='884.5' r='237.5' fill='%230BB3F0'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_975_3643' x='-515' y='697' width='1030' height='1030' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='200' result='effect1_foregroundBlur_975_3643'/%3E%3C/filter%3E%3Cfilter id='filter1_f_975_3643' x='-283' y='-400' width='1345' height='1345' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='200' result='effect1_foregroundBlur_975_3643'/%3E%3C/filter%3E%3Cfilter id='filter2_f_975_3643' x='-442' y='247' width='1275' height='1275' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='200' result='effect1_foregroundBlur_975_3643'/%3E%3C/filter%3E%3CclipPath id='clip0_975_3643'%3E%3Crect width='390' height='911' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  }

  .faq-container {
    padding: 50px 24px;
    h1 {
      font-size: 40px;
      line-height: 120%;
      text-align: center;
      margin: 0 0 30px;
    }

    img {
      display: none;
    }
  }

  .accordion {
    button {
      padding-right: 40px;
      padding-left: 0;
    }

    .accordion-content {
      padding-right: 0;
      margin-top: 24px;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: -0.02em;
    }

    .accordion-title {
      font-size: 20px;
      line-height: 150%;
    }
  }
}
