.terms {
  display: flex;
  justify-content: center;
}

.terms-container {
  max-width: 1092px;
  padding: 200px 170px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #111;

  h1 {
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin: 0;
  }

  h6 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    margin: 0 0 24px;
  }
}

.terms-items {
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  gap: 48px;
  background: #f3f5f7;
  border-radius: 30px;
}

.paragh-items {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.terms-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 1024px) {
  .terms-container {
    padding: 200px 90px 100px;
  }
}

@media screen and (max-width: 785px) {
  .terms-container {
    padding: 175px 25px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #111;

    h1 {
      font-size: 32px;
    }

    h6 {
      font-size: 20px;
    }
  }

  .terms-items {
    margin-top: 25px;
    padding: 25px;
    gap: 30px;
  }

  .paragh-items {
    gap: 18px;
  }

  .terms-text {
    font-size: 14px;
  }
}
