.expertise {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  color: #111111;
}
.expertise-container {
  padding: 100px 50px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.expertise-title {
  width: 50%;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 108px;
  margin: 0;

  span {
    color: #0bb3f0;
  }
}

.expertise-categories {
  margin-top: 50px;
  margin-bottom: 100px;
  max-width: 1412px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.expertise-item {
  width: 18%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #fff;
  position: relative;
  height: 330px;
  width: 259px;

  .expertise-item-title {
    z-index: 10;
  }

  &:last-child {
    font-size: 34px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.expertise-item:hover {
  .expertise-item-title {
    display: none;
  }
  .expertise-item-filters {
    display: flex;
    flex-direction: column;
    gap: 25px;
    user-select: none;
  }
}

.expertise-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(17, 17, 17, 0.4), rgba(17, 17, 17, 0.4));
  border-radius: 10%;
}
.expertise-item:hover::after {
  background: linear-gradient(rgba(17, 17, 17, 0.8), rgba(17, 17, 17, 0.8));
}

.expertise-item-filters {
  display: none;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  z-index: 10;
}

@media screen and (max-width: 1500px) {
  .expertise-categories {
    width: 80%;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
    justify-content: center;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1200px) {
  .expertise-title {
    width: 90%;
  }
}

@media screen and (max-width: 785px) {
  .expertise-container {
    padding: 50px 24px;
  }

  .expertise-title {
    font-size: 32px;
    line-height: 43px;
  }

  .expertise-categories {
    width: 100%;
    margin: 35px 0;
  }
}
