.ready {
  width: calc(100% - 100px);
  position: relative;
  background: #0bb3f0;
  padding: 100px 50px 50px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .mbt {
    margin-bottom: 100px;
    margin-top: 32px;
  }

  h1 {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 108px;
    text-align: center;
  }

  .description {
    margin: 28px 0 100px;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    max-width: 1412px;
  }
}

@media screen and (max-width: 785px) {
  .ready {
    padding: 50px 24px;
    width: calc(100% - 48px);

    h1 {
      font-size: 32px;
      line-height: 43px;
    }

    .mbt {
      margin-bottom: 50px;
      margin-top: 24px;
    }
  }
}
