.work {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.work-bg {
  background: url(../../assets/images/our-work/work-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}

.work-container {
  z-index: 1;
  color: #fff;
  max-width: 1172px;
  padding: 200px 170px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin: 0;
  }

  .work-desc {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    margin-top: 28px;
    text-align: center;
  }
}

.work-items {
  width: 100%;
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.work-items-square {
  position: relative;
}

.work-items-pair {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.work-arr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
}

.work-item {
  max-width: 450px;
  width: 38.4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  text-align: center;

  .img-box {
    height: fit-content;
  }

  img {
    max-width: 180px;
  }

  h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
    margin: 0;
  }

  span {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
  }
}

.work-items-pair {
  .work-item:last-child {
    margin-top: 200px;
  }
}

.customers {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.customers-bg {
  background: url(../../assets/images/our-work/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}

.customers-container {
  z-index: 1;
  color: #fff;
  max-width: 1412px;
  padding: 100px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;

  h1 {
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin: 0;
  }

  .swiper {
    padding: 0 35px !important;
  }

  .swiper-button-prev {
    left: 0;
    height: 100%;
    top: 0;
    background: transparent;
    width: 32px;
    margin-top: 0;
    color: white;
  }
  .swiper-button-next {
    right: 0;
    height: 100%;
    top: 0;
    background: transparent;
    width: 40px;
    margin-top: 0;
    color: white;
  }
}

.customers-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.customers-item {
  max-width: 437px;
  width: 30.95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-align: center;
    margin: 0;
    white-space: pre-line;
  }

  span {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
  }
}

@media screen and (max-width: 1024px) {
  .work-container {
    padding: 175px 50px 50px;

    h1 {
      font-size: 48px;
    }

    .work-desc {
      font-size: 18px;
      margin-top: 20px;
    }
  }

  .work-items {
    margin-top: 70px;
  }

  .work-arr {
    img {
      width: 160px;
    }
  }

  .work-item {
    gap: 20px;

    img {
      max-width: 140px;
    }

    h2 {
      font-size: 28px;
    }

    span {
      font-size: 18px;
    }
  }

  .work-items-pair {
    .work-item:last-child {
      margin-top: 100px;
    }
  }

  .customers-container {
    padding: 50px 25px;
    gap: 40px;

    h1 {
      font-size: 48px;
    }

    .swiper {
      padding: 0 30px !important;
    }

    .swiper-button-prev {
      width: 20px;
    }
    .swiper-button-next {
      width: 20px;
    }
  }

  .customers-item {
    gap: 15px;

    h2 {
      font-size: 28px;
    }

    span {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 785px) {
  .work-container {
    padding: 160px 25px 50px;

    h1 {
      font-size: 32px;
    }

    .work-desc {
      font-size: 16px;
    }
  }

  .work-items {
    gap: 30px;
  }

  .work-items-square {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .work-items-pair {
    flex-direction: column;
    gap: 30px;
  }

  .work-item {
    width: 100%;
    gap: 10px;

    img {
      max-width: 100px;
    }

    h2 {
      font-size: 20px;
    }

    span {
      font-size: 14px;
    }
  }

  .work-arr {
    display: none;
  }

  .work-items-pair {
    .work-item:last-child {
      margin-top: 0;
    }
  }

  .customers-bg {
    background-size: cover;
  }

  .customers-container {
    h1 {
      font-size: 32px;
    }
  }

  .customers-items {
    flex-direction: column;
    gap: 35px;
  }

  .customers-item {
    width: 100%;
    max-width: 400px;
    gap: 5px;

    h2 {
      font-size: 24px;
    }

    span {
      font-size: 16px;
    }
  }
}
