.filters {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 15px;
  padding-bottom: 15px;
  // border-bottom: 1px solid #111111;
  user-select: none;
  padding-right: 50px;
}

.filter {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  padding: 10px;
  background: #f3f5f7;
  border-radius: 15px;
  color: #111111;
  cursor: pointer;

  &.active {
    background: #0bb3f0;
    color: white;
    position: relative;
  }

  &.active:not(:first-of-type) {
    padding-right: 34px;
  }

  &.active:not(:first-of-type)::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto 0;
    width: 18px;
    height: 18px;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='1.07314' y1='-1.07314' x2='20.4842' y2='-1.07314' transform='matrix(0.67826 0.734822 -0.67826 0.734822 0.695312 2.15918)' stroke='white' stroke-width='2.14629' stroke-linecap='round'/%3E%3Cline x1='1.07314' y1='-1.07314' x2='20.4842' y2='-1.07314' transform='matrix(0.67826 -0.734822 0.67826 0.734822 2.07422 17.8408)' stroke='white' stroke-width='2.14629' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  }
}

@media screen and (max-width: 1305px) {
  .filters {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 76%;
    flex-wrap: wrap;
    padding-right: unset;
  }
}

@media screen and (max-width: 785px) {
  .filters {
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 15px;
    max-width: unset;
  }

  .filter {
    font-size: 20px;
    line-height: 24px;

    &.active:not(:first-of-type) {
      padding-right: 10px;
    }

    &.active:not(:first-of-type)::before {
      display: none;
    }
  }
}
