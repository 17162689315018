.hero-video {
  width: calc(100% - 100px);
  position: relative;
  background: #0bb3f0;
  padding: 100px 50px 50px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 108px;
    text-align: center;
  }

  .description {
    margin: 28px 0 100px;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    max-width: 1412px;
  }
}

.padTop {
  padding-top: 200px;
}

video {
  width: 100%;
  border-radius: 30px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25);
  max-width: 1412px;
}

.toggleSound {
  position: absolute;
  top: 50px;
  left: 50px;
  cursor: pointer;
  padding: 10px 10px 3px 10px;
  user-select: none;

  &:hover {
    background-color: rgba(189, 195, 199, 0.2);
    border-radius: 50%;
  }
}

@media screen and (max-width: 785px) {
  .hero-video {
    padding: 50px 24px;
    width: calc(100% - 48px);

    h1 {
      font-size: 32px;
      line-height: 43px;
    }

    .description {
      margin: 20px 0 40px;
      text-align: center;
      font-size: 24px;
      line-height: normal;
      max-width: 1412px;
    }
  }

  .padTop {
    padding-top: 140px;
  }
}
