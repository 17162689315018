.blog-details {
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.blog-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  .blog-tag {
    font-family: Galano Grotesque;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    background: #111;
    padding: 6px 10px;
    border-radius: 40px;
    white-space: nowrap;
    cursor: pointer;
  }
}

.blog-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 160px;
  gap: 20px;
  padding: 0 50px;
  color: #111111;

  .blog-date {
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;

    img {
      width: 16px !important;
      margin-right: 5px;
    }

    img:nth-child(2) {
      margin-left: 20px;
    }
  }

  .blog-title,
  h1 {
    font-weight: 700;
    font-size: 80px;
    margin: 0;
  }

  h2 {
    font-size: 32px;
    margin-top: 64px;
    margin-bottom: 16px;
  }

  h2:nth-child(1) {
    margin-bottom: 0;
    margin-top: 0;
  }

  h3 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.back-to-blogs {
  position: absolute;
  bottom: -80px;
  left: 0;
}

.blog-header-img {
  width: 1412px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  img {
    border-radius: 30px;
  }
}

.post-container {
  max-width: 932px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  figure {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 64px 0;
  }

  ol {
    padding-left: 15px;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  img {
    max-width: 780px;
    width: 100% !important;
    height: auto;
    border-radius: 30px;
  }

  a {
    text-decoration: underline !important;
    color: #0bb3f0;
  }

  .has-background {
    padding: 40px 20px;
    color: #fff;
    background: #111;
    border-radius: 30px;
    text-align: center;
    margin: 0 0 64px;
    font-size: 19px;
    font-weight: 500;

    a {
      color: #fff;
      text-decoration: underline;
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 785px) {
  .blog-details {
    width: calc(100% - 40px);
    overflow: hidden;
    margin: 0 auto;
  }

  .blog-details-container {
    width: 100%;

    .blog-date {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .blog-title,
    h1 {
      font-size: 40px;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 19px;
    }
  }

  .post-container {
    width: 100%;
  }

  .blog-tags {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 10px;
    top: 16px;
    left: 20px;

    .blog-tag {
      font-size: 14px;
      padding: 4px 8px;
    }
  }

  .back-to-blogs {
    width: 40px;
    bottom: -43px;
  }

  .blog-header-img {
    & > img {
      width: 100%;
    }
  }

  .carousel {
    width: 90%;
  }
}
