.linkform {
  background: #111111;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 170px;
  position: relative;
  color: #ffffff;
}

.linkform-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.linkform-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  margin-bottom: 66px;
  width: 85%;
}

.linkform-btn {
  display: block;
  width: 410px;
  margin-bottom: 0;
  background: #faca15;
  font-size: 20px;
  border: none;
  border-radius: 20px;
  font-family: "Galano Grotesque";
  font-style: normal;
  font-weight: 700;
  color: #111111;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.linkform-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;

  h6 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin: 0;
  }
}

@media screen and (max-width: 1200px) {
  .linkform-title {
    font-size: 48px;
  }

  .linkform-content {
    font-size: 18px;
  }
}

@media screen and (max-width: 785px) {
  .linkform {
    padding: 50px 25px;
  }

  .linkform-title {
    gap: 25px;
    font-size: 32px;
    margin-bottom: 46px;
    width: 100%;
  }

  .linkform-btn {
    width: 310px;
    height: 60px;
    display: flex;
  }

  .linkform-content {
    gap: 20px;
    font-size: 16px;

    h6 {
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 575px) {
}
