html {
  overflow-y: overlay;
}

body {
  height: 100%;
  margin: 0;
  max-width: 100%;
  font-family: "Galano Grotesque", sans-serif;
  overflow-x: hidden;
}

img {
  user-select: none;
}

::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border: 2px solid transparent;
  border-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
}

a {
  text-decoration: none;
  outline: none;
  color: #111111;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    outline: none;
  }
}

.fallback {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 80%;
    text-align: center;
    font-size: 3vw;
    line-height: 5vh;
    font-weight: 500;
    color: #111111;
  }
}

.video-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
