.media {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.media-container {
  color: #111;
  max-width: 1412px;
  padding: 200px 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin: 0;
  }

  .media-desc {
    width: 58%;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    margin-top: 28px;
    text-align: center;
  }
}

.media-items {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.media-item-bg {
  background-image: url(../../assets/images/our-work/work-bg.png) !important;
}

.media-item {
  position: relative;
  width: calc(100% - 160px);
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 80px;
  border-radius: 30px;
  color: #fff;
  background: #111;

  h2 {
    width: 70%;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    text-align: left;
  }

  .media-desc {
    text-align: left;
    margin-top: 16px;
  }
}

.media-categories-row,
.media-categories-row-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.media-categories-col {
  display: flex;
  flex-direction: column;
  width: 47%;
  max-width: 586px;
  justify-content: space-between;

  .media-subscribe {
    text-align: left;
  }

  .media-categories-item {
    width: 100%;
    align-items: flex-start;

    h3 {
      text-align: left;
      margin-top: 0;
      margin-bottom: 3px;
    }
    span {
      text-align: left;
      width: 100%;
    }
  }

  img {
    width: 100%;
    border-radius: 30px;
    margin-bottom: 40px;
  }
  .img-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    img {
      width: 48%;
    }
  }
}

.border-left-top-bottom {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-left: 6px;
}
.border-right-top-bottom {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-right: 6px;
}

.episode-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  a {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    display: flex;
    height: 60px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 20px;
    border: 1px solid #fff;
    width: 209px;
    text-align: right;
  }
}

.media-categories-item {
  width: 33.333333%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
  }

  h3 {
    margin-top: 40px;
    margin-bottom: 24px;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    white-space: pre-line;
    text-align: center;
  }

  span {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    width: 79%;
  }
}

.media-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
}

.media-subscribe {
  padding-top: 40px;
  border-top: 1px solid #faca15;
  text-align: center;
}

.media-btn {
  width: 184px;
}

.media-day {
  position: absolute;
  top: 80px;
  right: 80px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;

  h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-align: right;
  }
}

.blog-desc {
  width: 100% !important;
  text-align: center !important;
}

.media-blog-items {
  display: flex;
  justify-content: space-between;

  .media-blog-item {
    position: relative;
    border-radius: 30px;
    width: 23%;
    overflow: hidden;
  }

  .media-blog-item img {
    width: 100%;
    height: auto;
    border-radius: 30px;
  }

  .media-blog-desc {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
}

.media-blog-h3 {
  margin-top: 0 !important;
}

.media-blog-desc {
  padding: 40px;
  border: 1px solid #faca15;
  border-radius: 30px;
}

.engage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0 0;

  h1 {
    width: 48%;
  }

  .media-desc {
    width: 85%;
  }
}

@media screen and (max-width: 1200px) {
  .media-container {
    h1 {
      font-size: 48px;
    }

    .media-desc {
      font-size: 18px;
      margin-top: 20px;
    }
  }

  .media-items {
    margin-top: 50px;
    gap: 30px;
  }

  .media-item {
    width: calc(100% - 80px);
    gap: 30px;
    padding: 40px;

    h2 {
      width: 70%;
      font-size: 40px;
    }

    .media-desc {
      text-align: left;
      margin-top: 16px;
    }
  }

  .media-categories-item {
    h3 {
      margin-top: 20px;
      margin-bottom: 14px;
      font-size: 24px;
    }

    span {
      font-size: 18px;
      width: 79%;
    }
  }

  .media-text {
    font-size: 18px;
  }

  .media-subscribe {
    padding-top: 20px;
  }

  .media-btn {
    width: 164px;
  }

  .media-day {
    position: absolute;
    top: 40px;
    right: 40px;

    h2 {
      font-size: 24px;
    }
  }

  .media-blog-desc {
    padding: 20px;
  }

  .engage {
    h1 {
      width: 100%;
    }

    .media-desc {
      width: 100%;
    }
  }
}

@media screen and (max-width: 785px) {
  .media-container {
    padding: 150px 25px 50px;

    h1 {
      font-size: 32px;
    }

    .media-desc {
      font-size: 18px;
      margin-top: 18px;
      width: 100%;
    }
  }

  .media-item {
    width: calc(100% - 40px);
    gap: 30px;
    padding: 40px 20px;

    h2 {
      width: 100%;
      font-size: 26px;
      text-align: center;
    }
  }

  .media-categories-row,
  .media-categories-row-2 {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .media-day {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    align-items: center;
    margin-top: 10px;
    gap: 8px;
    h2 {
      text-align: center;
    }
  }

  .media-categories-item {
    width: 90%;

    h3 {
      margin-top: 14px;
      margin-bottom: 14px;
      font-size: 20px;
    }

    span {
      font-size: 18px;
      font-style: normal;
      width: 100%;
    }
  }

  .media-subscribe {
    padding-top: 30px;
    margin-top: 5px;
  }

  .media-text {
    font-size: 18px;
  }

  .border-left-top-bottom {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    margin-left: unset;
  }
  .border-right-top-bottom {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    margin-right: unset;
  }

  .media-categories-col {
    width: 100%;
    gap: 30px;

    .media-subscribe {
      text-align: center;
    }

    .media-categories-item {
      width: 100%;

      h3 {
        width: 100%;
        text-align: center;
        margin-top: 0;
        margin-bottom: 15px;
      }
    }

    img {
      margin-bottom: 0;
    }
  }

  .episode-btn {
    justify-content: center;
  }

  .media-blog-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    img {
      width: 90%;
    }
  }

  .engage {
    padding: 50px 0 0;

    h1 {
      width: 72%;
    }
  }
}
