.blog-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blog-list-container {
  max-width: 1412px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  a {
    display: flex;
    width: 31%;
    max-width: 450px;
  }
}

.blog-col {
  display: flex;
  flex-direction: column;
  width: 49%;
  gap: 50px;
  margin-top: 60px;
}

.blog-item-hero {
  max-width: 691px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 15px;

  img {
    width: 100%;
    height: auto;
    border-radius: 30px;
  }

  h1 {
    margin: 0;
    width: 72%;
  }
}

.blog-item-bg {
  width: 100%;
  aspect-ratio: 4 / 3;
  border-radius: 30px;
  background: linear-gradient(rgba(17, 17, 17, 0.1), rgba(17, 17, 17, 0.7));
}

.blog-item-text {
  position: relative;

  h4 {
    margin: 0;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    width: 70%;
  }

  span {
    position: absolute;
    right: 0;
    top: 16px;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
  }
}

@media screen and (max-width: 1300px) {
  .blog-item-text {
    h4 {
      font-size: 3vw;
      line-height: 120%;
    }

    span {
      font-size: 1.6vw;
      line-height: 2vw;
    }
  }
}

@media screen and (max-width: 785px) {
  .blog-list {
    align-items: center;
  }

  .blog-list-container {
    margin-top: 50px;
    flex-wrap: unset;
    flex-direction: column;
    max-width: 400px;
    gap: 50px;
    width: 100%;

    a {
      width: 100%;
    }
  }

  .blog-item-hero {
    margin-bottom: 16px;

    img {
      width: 100%;
    }
  }

  .blog-item-text {
    display: flex;
    flex-direction: column;

    h4 {
      width: 100%;
      max-width: 100%;
      font-size: 26px;
      line-height: 35px;
    }

    span {
      position: relative;
      top: unset;
      right: unset;
      font-size: 16px;
      line-height: 22px;
      margin-top: 8px;
    }
  }
}
