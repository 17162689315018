.purpose {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
}

.purpose-container {
  max-width: 880px;
  padding: 100px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.purpose-title {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 108px;
  margin: 0;
}

.purposes {
  margin-top: 40px;
  max-width: 1170px;
  text-align: center;
}

.purposes-item {
  position: relative;
  user-select: none;
  letter-spacing: 0.5px;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 65px;
  z-index: 1;

  img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: -1;
  }

  &:hover {
    img {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 1055px) {
  .purposes {
    max-width: 580px;
  }

  .purposes-item {
    &:nth-child(2),
    &:nth-child(10) {
      display: none;
    }
  }
}

@media screen and (max-width: 785px) {
  .purpose-container {
    padding: 50px 50px;
  }

  .purpose-title {
    font-size: 32px;
    line-height: 43px;
  }

  .purposes {
    display: flex;
    flex-direction: column;
  }

  .purposes-item {
    &:nth-child(2n) {
      display: none;
    }
  }

  .purposes-item {
    font-size: 26px;
    line-height: 35px;
  }
}
