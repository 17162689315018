.projects-list {
  position: relative;
}

.project-container {
  h4 {
    margin: 0;
    font-weight: 700;
    font-size: 40px;
    line-height: 72px;
    text-align: center;
  }

  h5 {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 43px;
    text-align: center;
  }
}

.projects-grid-table {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 50px;
  max-width: 1300px;
}

.projects-photos-table {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 50px;
}

.projects-photos-table {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  transition: opacity 0.15s ease-out;

  img {
    width: 100%;
  }
}

.video-container {
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: #000;
  border-radius: 30px;
  max-width: 420px;

  video {
    width: 100%;
    height: 100%;
  }
}

.project-container-hero {
  overflow: hidden;
  border-radius: 30px;
  max-width: 420px;
  width: 100%;
  aspect-ratio: 1 / 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
  }
}

.projects-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  gap: 8px;
  width: 410px;
  background: #faca15;
  border-radius: 20px;
  font-size: 20px;
  line-height: 120%;
  font-weight: 700;
  cursor: pointer;
}

.filter-block {
  display: flex;
  flex-direction: column;
}

.filters-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.clear-btn {
  margin-top: 22px;
  margin-left: 60px;
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 8px;
  border: 1px solid #e35019;
  color: #e35019;
  border-radius: 8px;
  right: 0;
  top: 8px;
  cursor: pointer;
  user-select: none;
  z-index: 4;
}

.subfilters-group {
  position: relative;
}

.show-more-btn {
  position: absolute;
  top: 0;
  right: -140px;
  width: 115px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  background: #0bb3f0;
  border-radius: 15px;
  color: white;
  user-select: none;
  cursor: pointer;
}

@media screen and (max-width: 1305px) {
  .clear-btn {
    margin-top: 38px;
  }
}

@media screen and (max-width: 785px) {
  .project-container {
    margin-top: 25px;

    h4 {
      font-size: 26px;
      line-height: 36px;
    }

    h5 {
      font-size: 18px;
      line-height: 25px;
    }
  }
  .projects-grid-table {
    grid-template-columns: repeat(2, 1fr);
  }

  .project-container-hero {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    aspect-ratio: unset;
    img {
      max-width: 380px;
    }
  }

  .projects-button {
    max-width: 370px;
    padding: 18px 0;
    width: 100%;
    margin-top: 50px;
  }

  .clear-btn {
    margin-top: 22px;
  }
}

@media screen and (max-width: 525px) {
  .projects-grid-table {
    grid-template-columns: repeat(1, 1fr);
  }
}
