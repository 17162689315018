.ourblog {
  width: 100%;
  overflow: hidden;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
}

.ourblog-container {
  padding: 100px 50px;
  max-width: 1412px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ourblog-title {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 108px;
  margin: 0;
}

.graphic-blog-bg {
  background-image: url(../../assets/images/our-work/work-bg.png) !important;
}

.graphic-blog {
  margin: 50px 0 80px;
  position: relative;
  width: calc(100% - 160px);
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 80px;
  border-radius: 30px;
  color: #fff;
  background: #111;

  .linkform-btn {
    width: 250px;
  }

  .media-categories-row-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .media-categories-col {
    display: flex;
    flex-direction: column;
    width: 47%;
    max-width: 586px;
    justify-content: space-between;

    .media-categories-item {
      width: 100%;
      align-items: flex-start;

      h3 {
        text-align: left;
        margin-top: 0;
        margin-bottom: 3px;
        font-size: 32px;
        line-height: 150%;
      }
      span {
        text-align: left;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 180%;
        width: 100%;
      }
    }

    img {
      width: 100%;
      border-radius: 30px;
      margin-bottom: 40px;
    }
    .img-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      img {
        width: 48%;
      }
    }
  }

  .episode-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    a {
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      display: flex;
      height: 60px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 20px;
      border: 1px solid #fff;
      width: 209px;
      text-align: right;
    }
  }
}

.single-blog-img {
  position: relative;

  .blog-tags {
    position: absolute;
    left: 30px;
    top: 26px;

    .blog-tag {
      background: #343434;
    }
  }

  .blog-author {
    position: absolute;
    left: 30px;
    bottom: 70px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  }
}

@media screen and (max-width: 785px) {
  .ourblog-container {
    padding: 154px 24px 50px;
    width: calc(100% - 40px);
  }

  .ourblog-title {
    font-size: 40px;
    line-height: 120%;
    text-align: center;
  }

  .graphic-blog {
    margin: 25px 0 20px;
    width: calc(100% - 40px);
    padding: 30px 20px;

    .linkform-btn {
      margin: 20px auto 0;
    }

    .media-categories-row-2 {
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }

    .media-categories-col {
      width: 100%;

      .media-categories-item {
        h3 {
          font-size: 24px;
          line-height: 150%;
        }
        span {
          font-size: 18px;
        }
      }

      img {
        margin-bottom: 20px;
      }
    }

    .episode-btn {
      justify-content: center;
    }
  }

  .single-blog-img {
    .blog-tags {
      position: absolute;
      left: 16px;
      top: 20px;
      justify-content: unset;

      .blog-tag {
        background: #343434;
      }
    }

    .blog-author {
      position: absolute;
      left: 16px;
      bottom: 50px;
    }
  }
}
