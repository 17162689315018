form {
  max-width: 511px;
  min-width: 310px;
  width: 77%;
  background: #ffffff;
  border-radius: 30px;
  padding: 30px;
  font-weight: 700;
  color: #111111;
  font-size: 20px;

  .form-content {
    display: flex;
    flex-direction: column;
  }

  label {
    line-height: 120%;
    margin-bottom: 10px;
  }

  textarea,
  input {
    font-family: "Galano Grotesque";
    font-style: normal;
    font-weight: 500;
    background: #f3f5f7;
    border: 2px solid #e0e0e0;
    margin-bottom: 15px;
    border-radius: 20px;
    color: #111111;
    font-size: 20px;
    padding: 8px;
  }

  input {
    height: 40px;
  }

  textarea {
    height: 100px;
    resize: none;
  }

  button {
    margin-bottom: 0;
    margin-top: 15px;
    background: #faca15;
    font-size: 20px;
    border: none;
    border-radius: 20px;
    font-family: "Galano Grotesque";
    font-style: normal;
    font-weight: 700;
    color: #111111;
    font-size: 20px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .send {
    margin-left: 5px;
    width: 20px;
    height: 20px;
  }
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(17, 17, 17, 0.65);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  background: #ffffff;
  width: 380px;
  padding: 30px 30px 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 5px solid #111111;
  border-radius: 30px;

  & > img {
    margin-bottom: 16px;
  }

  span:first-of-type {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  span:last-of-type {
    font-weight: 500;
    font-size: 20px;
    line-height: 180%;
  }
}

.modal-close {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -30px;
  top: -30px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

@media screen and (max-width: 785px) {
  form {
    max-width: 311px;
  }

  .modal {
    align-items: flex-end;

    .modal-content {
      margin-bottom: 300px;
    }
  }
}

@media screen and (max-width: 575px) {
  form {
    padding: 20px;
  }

  .modal {
    .modal-content {
      width: 325px;
      padding: 30px 0px 22px;
    }
  }
}
